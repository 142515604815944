<template>
  <section class="container detail-page">
    <div class="nav-container">
      <div class="nav-content">
        <ul class="logo-wrapper">
          <li class="jk-logo" @click="jumpPage('/')">
            <img src="@/assets/img/main/logo-hover.png" alt="">
          </li>
          <li class="lx-logo" @click="jumpPage('https://www.lingxidata.cn/')">
            <img src="@/assets/lingxi/logo.png" alt="">
          </li>
        </ul>
        <ul class="menu-warpper">
          <li class="menu-item"><span @click="jumpPage('https://www.cmstjd.com/')">中储京科</span></li>
          <li class="menu-item">
            <el-dropdown trigger="hover">
              <span class="nav-title">
                找仓租仓
              </span>
              <el-dropdown-menu class="my-dropdown-menu" slot="dropdown">
                <el-dropdown-item>
                  <router-link to="/map">地图找库</router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link to="/askforrent">仓库求租</router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

          </li>
          <li class="menu-item"><router-link class="nav-title" to="/recruitment"><span>人才招聘</span></router-link></li>
          <li class="menu-item"><router-link class="nav-title" to="/jinrong"><span>金融产品</span></router-link></li>
        </ul>
      </div>
    </div>

    <div class="main-container">
      <div class="top">
        <div class="tip">
          <el-breadcrumb separator-class="el-icon-arrow-right" style="font-size: 18px">
            <el-breadcrumb-item class="sec">仓库名称</el-breadcrumb-item>
            <el-breadcrumb-item>
              <span class="last">{{
            warehouse.warehouseName || ""
          }}</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="main">
          <div class="left">
            <div class="pic">
              <div class="">
                <div v-if="isVr == 'VRPic'">
                  <div class="middlePic" @click="jump">
                    <img src="../assets/img/detail/vr-icon.png" alt="" />
                  </div>
                  <el-image style="width: 100%; height: 543px" v-if="VRPicList[0]" :src="VRPicList[0].url"
                    fit="cover"></el-image>
                </div>
                <div class="detailSwiper">
                  <swiper v-if="isVr == 'imgV'" :options="swiperOption" ref="mySwiper">
                    <swiper-slide v-for="(it, ind) in images" :key="ind">
                      <el-image style="width: 814px; height: 544px" :src="it.url"
                        :preview-src-list="srcListvr"></el-image>
                    </swiper-slide>
                  </swiper>
                  <div v-if="isVr == 'imgV'" class="swiper-button-prev"></div>
                  <div v-if="isVr == 'imgV'" class="swiper-button-next"></div>
                </div>

                <div v-if="isVr == 'cang'">
                  <el-image style="width: 100%; height: 543px" :src="wareHousePics[0].url" fit="cover"></el-image>
                </div>
              </div>
            </div>
            <div class="bottomPic">
              <div class="leftText" :class="{ swiperActive: isVr == 'VRPic' }" @click="isVr = 'VRPic'"
                v-if="VRPicList.length != 0">
                VR预览
              </div>
              <div class="rightText" :class="{
            swiperActive:
              (isVr == 'imgV' && VRPicList.length != 0) ||
              (isVr == 'imgV' && wareHousePics.length != 0),
          }" @click="isVr = 'imgV'">
                图片预览
              </div>
              <div v-if="wareHousePics.length != 0" :class="{ swiperActive: isVr == 'cang' }" class="rightText"
                @click="isVr = 'cang'">
                仓库位图
              </div>
            </div>
          </div>
          <div class="right">
            <div class="topText">
              <div class="title">
                {{ warehouse.warehouseName ? warehouse.warehouseName : "占位" }}
              </div>
              <div class="tip" v-if="warehouse.isCustomer">
                <div class="left">已上线货兑宝</div>
                <div class="right">
                  <div class="one">数字仓储</div>
                  <div class="one">线上过户</div>
                  <div class="one">仓单融资</div>
                </div>
              </div>

              <div class="views">
                <div class="viewsPic">
                  <!-- <img
                  class="pic"
                  src="../assets/img/detail/liulanliang@2x.png"
                  alt=""
                /> -->
                </div>
                <div class="text">浏览量：{{ warehouse.visitCount }}</div>
              </div>
            </div>
            <div class="floor-line"></div>
            <div class="middleText">
              <div class="one">
                <div class="oneTop">{{ warehouse.landArea }}</div>
                <div class="oneText">仓库占地</div>
              </div>
              <div class="one">
                <div class="oneTop">{{ warehouse.outdoorsArea }}</div>
                <div class="oneText">露天货场</div>
              </div>
              <div class="one">
                <div class="oneTop">{{ warehouse.warehouseArea }}</div>
                <div class="oneText">库房面积</div>
              </div>
            </div>
            <div class="floor-line"></div>
            <div class="bottomText">
              <div class="bottomTextTop">
                <div class="bottomOne">
                  <div class="oneLeft">仓库类型:</div>
                  <div class="oneRight">{{ pinlieTags }}</div>
                </div>
                <div class="bottomOne">
                  <div class="oneLeft">存放品类:</div>
                  <div class="oneRight">{{ pinlie }}</div>
                </div>
                <div class="bottomOne">
                  <div class="oneLeft">铁路专线:</div>
                  <div class="oneRight" v-if="warehouse.railwayCount">
                    {{ warehouse.railwayCount }}条；{{
            warehouse.railwayLength
          }}公里
                  </div>
                  <div v-else class="oneRight">无</div>
                </div>
                <div class="bottomOne">
                  <div class="oneLeft">仓库地址:</div>
                  <div class="oneRight">{{ warehouse.address }}</div>
                </div>
              </div>
            </div>
            <div class="bottomTextTip">
              <div class="tipCard" v-for="(it, ind) in featureTags" :key="ind">
                {{ it }}
              </div>
              <!-- <div class="tipCard">期货交割</div> -->
            </div>
          </div>
        </div>
        <div class="canshu"></div>
      </div>
      <div class="center-container">
        <div class="margin-center flex space-around">
          <div class="left">
            <div class="change-tab flex">
              <div class="tab-item" v-for="(item, index) in tabList" :key="index">
                {{ item }}
              </div>
            </div>
            <div class="content-detail">
              <img src="../assets/img/detail/poperty-title.png" alt="" class="title-img" />
              <div class="substance flex">
                <ul>
                  <li>
                    <span class="sub-title"> 防火等级:</span><span class="details">{{ fireLevel }}</span>
                  </li>
                  <li>
                    <span class="sub-title">地面承重:</span><span class="details">{{ warehouse.bearing }} T/M²</span>
                  </li>
                  <li>
                    <span class="sub-title">月台宽度:</span><span class="details">{{ warehouse.railwayPlatformWidth }}
                      M</span>
                  </li>
                  <li>
                    <span class="sub-title">雨棚深度:</span><span class="details">{{ warehouse.deep }} M</span>
                  </li>
                </ul>
                <ul>
                  <li>
                    <span class="sub-title">建筑结构：</span>
                    <!-- （1砖混 2钢混 3轻钢结构 4简易库） -->
                    <span class="details">{{ jiegou }}</span>
                  </li>
                  <li>
                    <span class="sub-title">库房顶高：</span><span class="details">{{ warehouse.high }} M</span>
                  </li>
                  <li>
                    <span class="sub-title">月台高度：</span><span class="details">{{ warehouse.platformHigh }} M</span>
                  </li>
                  <li>
                    <span class="sub-title">升降平台：</span>
                    <span class="details">{{
            warehouse.columnSpacing == "1" ? "有" : "无"
          }}</span>
                  </li>
                </ul>
                <!-- <ul>
                <li v-for="(item, index) in substanceContent1" :key="index">
                  <span class="sub-title">{{ item.subtitle }}:</span
                  ><span class="details">{{ item.detail }}</span>
                </li>
              </ul>
              <ul>
                <li v-for="(item, index) in substanceContent2" :key="index">
                  <span class="sub-title">{{ item.subtitle }}:</span
                  ><span class="details">{{ item.detail }}</span>
                </li>
              </ul> -->
              </div>
            </div>
          </div>
          <div class="right" v-if="warehouse.brokers">
            <img src="../assets/img/detail/QR-upperRightCorner.png" alt="" class="QR-upperRightCorne" v-if="isMaxQrcode"
              @mouseover="isMaxQrcode = false" />

            <!-- 电脑 -->
            <div v-if="isMaxQrcode">
              <div class="title">中储京科供应链管理有限公司</div>
              <div class="introduce">
                <span class="position-name">营销顾问</span>
                <ul>
                  <li>
                    <span>姓名：</span><span>{{ warehouse.brokers[0].brokerName }}</span>
                  </li>
                  <li>
                    <span>电话：</span><span>{{ warehouse.brokers[0].phone }}</span>
                  </li>
                </ul>
              </div>
              <div class="bottom-title">
                {{ warehouse.brokers[0].description }}
              </div>
            </div>
            <!-- 微信 -->
            <div v-else class="QR-upperRightCorne is-max-two" @mouseleave="isMaxQrcode = true">
              <img src="../assets/img/detail/diannao.png" alt="" class="QR-upperRightCorne" />
              <div class="wx-box">
                <img class="wx-er" :src="warehouse.brokers[0].qrcodeUrl" alt="" />
                <div class="wx-sao">
                  <img class="wx-sao-img" src="../assets/img/detail/weixinfang.png" alt="" />
                  <div class="wx-sao-text">微信扫一扫</div>
                </div>
              </div>
              <div class="wx-foot">扫码小程序 移动看库更便捷</div>
            </div>
          </div>
        </div>
      </div>
      <div class="center-container2">
        <div class="container2-content">
          <img src="../assets/img/detail/summary-title.png" alt="" class="continer-img" />
          <div class="content-details">
            <div ref="box_css">
              <div id="box-css" class="content-details-box ql-editor" ref="box_css_child"
                v-html="warehouse.description"></div>
            </div>
            <!-- <div
            class="details-item"
            v-for="(item, index) in contentDetails"
            :key="index"
          >
            <div class="item-title">{{ item.title }}</div>
            <div class="item-content">{{ item.content }}</div>
          </div> -->
          </div>
        </div>
      </div>

      <div class="center-container3">
        <div class="container3-content">
          <div class="w12 tabs">
            <el-tabs v-model="activeName" type="card">
              <el-tab-pane v-for="(it, ind) in mapTabs" :key="ind" :label="it.selfname" :name="String(ind)">
                <div class="tabs__item" slot="label" @click="handleClick(it)">
                  {{ it.selfname }}
                </div>
              </el-tab-pane>
            </el-tabs>
            <div class="flex" style="display: flex; align-items: center">
              <img class="address-img" src="../assets/address.png" alt="" />
              <div class="address-name">当前仓库位置</div>
            </div>
          </div>
          <div class="bdmap w12" style="height: 500px" v-if="isFinished">
            <baidu-map class="maps" :center="center" :min-zoom="4" :zoom="zoom" :scroll-wheel-zoom="true"
              @ready="mapReady">
              <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showZoomInfo="false"></bm-navigation>

              <el-collapse accordion>
                <el-collapse-item>
                  <template v-slot:title>
                    <div class="p" style="padding-left: 20px">
                      点击展开相关地点 <span>{{ mapTabsInfo.length }}</span> 个
                    </div>
                  </template>
                  <div class="maps-info" v-for="(it, ind) in mapTabsInfo" :key="ind" @click="signClick(it)">
                    <div class="flex">
                      <div class="icon"></div>
                      <div class="name">{{ it.name }}</div>
                    </div>
                    <div class="flex spb">
                      <div class="location" style="font-size: 13px">
                        {{ it.address }}
                      </div>
                      <div class="distance" style="font-size: 13px">
                        距离约{{ it.distance }}公里
                      </div>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>

              <!-- 标点 -->
              <bm-marker v-for="(item, ind) of mapTabsInfo" :key="ind"
                :position="{ lng: item.longitude, lat: item.latitude }" @click="infoWindowOpen" :dragging="true"
                :top="true">
                <!-- <bm-label :content="item.name" :labelStyle="{color: 'red', fontSize : '24px'}" :offset="{width: -35, height: 30}"/> -->
                <!--                红点-->
                <bm-info-window :show="show" @close="infoWindowClose" @open="infoWindowOpen">
                  <p style="font-size: 13px">{{ item.name }}</p>
                  <p style="font-size: 13px; margin-top: 10px; color: #999">
                    {{ item.address }}
                  </p>
                </bm-info-window>
              </bm-marker>

              <!--              黄点-->
              <bm-marker :position="center" :dragging="true" animation="BMAP_ANIMATION_BOUNCE"
                @click="infoWindowOpenTwo" :icon="{ url: pointIcon, size: { width: 40, height: 60 } }" ref="myMap">
                <bm-info-window :show="showTwo" @close="infoWindowCloseTwo" @open="infoWindowOpenTwo">
                  <p style="font-size: 13px">
                    {{ warehouse.warehouseName }}
                  </p>
                  <p style="font-size: 13px; margin-top: 10px; color: #999">
                    {{ warehouse.address }}
                  </p>
                </bm-info-window>
              </bm-marker>
              <!-- <bm-marker
                position="{lng: 116.4, lat: 39.9}"
                :dragging="true"
                @click="infoWindowOpen"
              >
                <bm-info-window
                  :show="show"
                  @close="infoWindowClose"
                  @open="infoWindowOpen"
                  style="font-size: 13px"
                >
                  北京xxxx <br /><br />
                  地址：北京市
                </bm-info-window>
              </bm-marker> -->
            </baidu-map>

            <!-- <template v-if="mapTabsInfo.length"> -->
            <!-- <bm-marker

                :position="{ lng: item.latitude, lat: item.longitude }"
                :icon="{
                  url: '../assets/img/main/address-icon.png',
                  size: { width: 50, height: 50 },
                }"
                @click="lookDetail(item)"
              >
              </bm-marker> -->
            <!-- </template> -->
          </div>
        </div>
      </div>
      
    </div>

    <Footer></Footer>
  </section>
</template>


<script>
import lingxi from "../api/lingxi";
import { BmMarker } from "vue-baidu-map";
export default {
  comments: {
    BmMarker,
  },
  data() {
    return {
      isDetail: false,
      show: false,
      showTwo: false,
      isFinished: false,
      pointIcon: require("../assets/img/detail/address.png"),
      isMaxQrcode: true,
      swiperOptionCard: {
        slidesPerGroup: 4,
        slidesPerView: 4,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      swiperOption: {
        loop: false,
        effect: "slide",
        autoplay: {
          delay: 5 * 1000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      isVr: "",

      center: { lng: "", lat: "" },

      //地图展示级别
      zoom: 15,
      warehouse: {
        warehouseName: "",
        categories: [],
      },
      contentDetails: [
        {
          title: "企业背景",
          content:
            "青州中储物流有限公司是中储发展股份有限公司在山东地区的物流中心。公司始建于1957年，企业占地18万平方米，现有库房44栋，库房总建筑面积8万m。年物资吞吐能力150万吨，仓储能力15万吨。 库内铁路专用线长800米，各类起重、运输设备齐全，配送辐射范围可至全国。",
        },
        {
          title: "区位优势",
          content:
            "位于山东省中部的青州市，距火车站仅-公里，南邻胶济铁路，北靠309国道和济青高速(G20) 公路，东营至临沂高速(G25)公路横贯南北，交通条件便利，是开展综合物流业务的理想场所。",
        },
        {
          title: "业务定位",
          content:
            "提供科学合理的个性化物流解决方案及物流咨询服务;各类货物的仓储保管、运输配送、中转联运等。",
        },
      ],
      tabList: [],
      substanceContent1: [
        { subtitle: "防火等级", detail: "丙二类" },
        { subtitle: "地面承重", detail: "5 T/MI" },
        { subtitle: "月台宽度", detail: "0 M" },
        { subtitle: "雨棚深度", detail: "5M" },
      ],
      substanceContent2: [
        { subtitle: "建筑结构", detail: "轻钢结构" },
        { subtitle: "库房顶高", detail: "12M" },
        { subtitle: "月台高度", detail: "0M" },
        { subtitle: "升降平台", detail: "无" },
      ],
      featureTags: [],
      myMapExample: null,
      mapTabs: [],
      mapTabsInfo: [],
      mapTabsData: [],
      url: null,
      srcListvr: [],
      srcListimg: [],
      srcListware: [],
      activeName: "0",
      VRPicList: [], // vr
      images: [], // banner
      wareHousePics: [], // 库区平面图
    };
  },
  computed: {
    jiegou() {
      // （1砖混 2钢混 3轻钢结构 4简易库）
      if (this.warehouse.propertyStructure == "1") {
        return "砖混";
      }
      if (this.warehouse.propertyStructure == "2") {
        return "钢混";
      }
      if (this.warehouse.propertyStructure == "3") {
        return "轻钢结构";
      }
      if (this.warehouse.propertyStructure == "4") {
        return "简易库";
      }
    },
    fireLevel() {
      if (this.warehouse.fireRating == 1) {
        return "甲类";
      }
      if (this.warehouse.fireRating == 2) {
        return "乙一类";
      }
      if (this.warehouse.fireRating == 3) {
        return "乙二类";
      }
      if (this.warehouse.fireRating == 4) {
        return "丙一类";
      }
      if (this.warehouse.fireRating == 5) {
        return "丙二类";
      }
      if (this.warehouse.fireRating == 6) {
        return "丁类";
      }
      if (this.warehouse.fireRating == 7) {
        return "戊类";
      }
    },
    pinlie() {
      let text = "暂无";
      if (this.warehouse.categories.length > 0) {
        text = "";
        this.warehouse.categories.forEach((it) => {
          if (text == "") {
            text = it.categoryName;
          } else {
            text += "/" + it.categoryName;
          }
        });
      }
      return text;
    },
    pinlieTags() {
      let text = "暂无";

      if (
        this.warehouse &&
        this.warehouse.tags &&
        this.warehouse.tags.length > 0
      ) {
        text = "";
        this.warehouse.tags.forEach((it) => {
          if (text == "") {
            text = it.itemName;
          } else {
            text += "/" + it.itemName;
          }
        });
      }
      return text;
    },
  },
  mounted() {
    // if (window.location.href.indexOf("#reloaded") == -1) {
    //   window.location.href = window.location.href + "#reloaded";

    //   window.location.reload();
    // }
    // document.getElementById("nav").classList.add("fixedNav");
  },
  destroyed() {
    // document.getElementById("nav").classList.remove("fixedNav");
  },
  methods: {
    moreClick() {
      this.$router.push({
        path: "map",
      });
    },
    cardClick(item) {
      let pathInfo = this.$router.resolve({
        path: "detail",
        query: {
          id: item.warehouseId,
        },
      });
      window.open(pathInfo.href, "_blank");

      this.getDetail();
    },
    infoWindowClose() {
      this.show = false;
    },
    infoWindowOpen(e) {
      this.show = true;
      var point = new BMap.Point(e.point.lng, e.point.lat);
      this.myMapExample.setCenter(point); // 设置地图中心点
      this.myMapExample.setZoom(14); // 设置地图中心点
    },
    infoWindowCloseTwo() {
      this.showTwo = false;
    },
    infoWindowOpenTwo() {
      this.showTwo = true;
      var point = new BMap.Point(this.center.lng, this.center.lat);
      this.myMapExample.setCenter(point); // 设置地图中心点
      this.myMapExample.setZoom(14); // 设置地图中心点
    },
    handleClick(it) {
      // console.log("it", it);
      this.show = false;
      this.mapTabsInfo = it.data;
      const pointArray = [];
      it.data.forEach((item, index) => {
        pointArray[index] = new BMap.Point(item.longitude, item.latitude);
      });
      pointArray.push(new BMap.Point(this.center.lng, this.center.lat));
      this.myMapExample.setViewport(pointArray);
    },

    getDetail() {
      window.scrollTo(0, 0);
      lingxi.warehouseBannerList(this.$route.query.id).then((res) => {
        // console.log(555, res);
        Object.keys(res.data).forEach((k) => {
          if (k == 1) {
            this.VRPicList = res.data[k];
            this.VRPicList.forEach((it) => {
              this.srcListvr.push(it.url);
            });
            // this.srcListvr
          } else if (k == 2) {
            this.images = res.data[k];
            this.images.forEach((it) => {
              this.srcListvr.push(it.url);
            });
          } else if (k == 3) {
            this.wareHousePics = res.data[k];
          }
        });
        if (this.VRPicList.length != 0) {
          this.isVr = "VRPic";
        } else {
          this.isVr = "imgV";
        }
        // console.log(" this.VRPicList", this.VRPicList);
      });

      lingxi.warehouseDetailById(this.$route.query.id).then((res) => {
        this.warehouse = res.data;
        this.tabList = res.data.service.split("、");
        this.center.lng = this.warehouse.longitude;
        this.center.lat = this.warehouse.latitude;

        document.title = this.warehouse.warehouseName + this.$route.meta.title;

        // this.isVr = res.data.vrAddress ? true : false;

        const featureTags = res.data.featureTags || [];
        featureTags.map((it) => {
          this.featureTags.push(it.itemName);
        });

        if (res.data.mapConfigs) {
          Object.keys(res.data.mapConfigs).forEach((e, i) => {
            // this.handleClick({ selfname: e, data: res.data.mapConfigs[e] });
            this.mapTabs.push({ selfname: e, data: res.data.mapConfigs[e] });

            // this.mapTabsData.push(res.data.mapConfigs[e])
          });
        }

        if (this.mapTabs.length) {
          this.mapTabsInfo = this.mapTabs[0].data;
        }
        console.log(111);
        this.isFinished = true;
      });
    },
    jump() {
      if (this.VRPicList[0].toUrl) {
        window.open(this.VRPicList[0].toUrl);
      }
    },
    signClick(item) {
      this.show = true;
      // console.log("item", item);
      var point = new BMap.Point(item.longitude, item.latitude);
      this.myMapExample.setCenter(point); // 设置地图中心点
      this.myMapExample.setZoom(14); // 设置地图中心点
    },
    jumpPage(url) {
      if (url == '/') {
        return this.$router.push("/")  
      }
      window.open(url);
    },
    mapReady({ BMap, map }) {
      this.$nextTick(() => {
        const pointArray = [];

        // this.mapTabs[0].data.forEach((item, index) => {
        //   pointArray[index] = new BMap.Point(item.longitude, item.latitude);
        // });

        // pointArray.push(new BMap.Point(this.center.lng, this.center.lat));

        this.myMapExample = map;

        // map.setViewport(pointArray);
      });
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$("#box-css p:nth-child(odd)").addClass("odd-p");
      this.$("#box-css p:nth-child(even)").addClass("even-p");
    });
  },

  beforeCreate() {
    window._bd_share_main = "";
  },
  created() {
    // window.location.reload();
    this.getDetail();
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log(val);
        console.log(oldVal);
        // if(oldVal.)
        // window.location.reload();
      },
      // 深度观察监听
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped lang="less">
@import url("../assets/styles/var.less");

.nav-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .nav-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;

    .logo-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;

      .jk-logo {
        height: 30px;
        margin-right: 30px;
        cursor: pointer;
      }

      .lx-logo {
        height: 45px;
        cursor: pointer;
      }
    }

    .menu-warpper {
      display: flex;
      align-items: center;

      .menu-item {
        margin-left: 20px;

        span {
          color: #999;
          cursor: pointer;

          &:hover {
            color: #00b079;
          }
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.main-container {
  padding-top: 100px;
}

/deep/i {
  color: #666;
}

.detailSwiper {
  --swiper-navigation-color: #fff;
  /* 单独设置按钮颜色 */
  --swiper-navigation-size: 40px;
  /* 设置按钮大小 */
}


/deep/.even-p strong {
  font-size: 19px;
}

/deep/.el-image-viewer__actions .el-image-viewer__actions__inner i {
  color: #fff !important;
}

/deep/.swiper-pagination-bullet-active {
  background: #00b079;
}

.box-vr {
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 40%;
  margin-left: -30px;
  margin-top: -30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: url("../assets/img/main/vr-round.png") no-repeat;
  background-size: 60px 60px;
  background-position: center;
  // margin: 0 auto 16px;
}

.detail-page .content-details .odd-p {
  font-size: 18px;
}

/deep/.detail-page .content-details .odd-p span {
  font-size: 18px !important;
}

.flex {
  display: flex;
}

.location {
  width: 211px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.margin-center {
  width: 1221px;
  margin: auto;
}

.space-around {
  justify-content: space-around;
}

.container {
  background: #f7faf9;
}

.slide-swiper-dot {
  margin-bottom: 30px;
}

.top {
  width: 100%;
  height: 657px;
  background: url("../assets/img/detail/banner@2x.png") no-repeat;
  background-size: 100% auto;
  margin: auto;

  >.tip {
    padding: 26px 0px;
    width: 1200px;
    margin: auto;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;

    .last {
      color: @primary;
      font-weight: normal;
      font-size: 18px;

      &:hover {
        font-weight: bold;
      }
    }
  }

  .floor-line {
    border-bottom: 1px solid #f5f6f8;
  }

  >.main {
    // padding: 0px 362px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1200px;
    margin: auto;

    >.left {
      width: 814px;
      height: 544px;
      position: relative;

      //   background-color: aqua;
      >.pic {
        z-index: 10;
        width: 100%;
        height: calc(544px - 60px);
        background: url("../assets/img/detail/矩形 27 拷贝 2@2x.png") no-repeat;
        background-size: 100% 100%;
        position: relative;

        .middlePic {
          width: 300px;
          height: 300px;
          z-index: 1000;
          position: absolute;
          left: 407px;
          top: 272px;
          transform: translate(-50%, -50%);
          background: url("../assets/img/detail/0@2x.png") no-repeat;
          background-size: 198px 198px;
          background-position: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          animation: VRwidth 2s infinite linear;

          img {
            width: 51px;
            height: 34px;
          }
        }

        @keyframes VRwidth {
          0% {
            background-size: 180px 180px;
          }

          // 50% {
          //   background-size: 270px 270px;
          // }
          100% {
            background-size: 350px 350px;
          }
        }
      }

      .bottomPic {
        position: absolute;
        bottom: 0;
        width: 814px;
        height: 60px;
        z-index: 1000;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: fade(@primary, 50%);
        background-size: 100%;

        >.leftText,
        .rightText {
          cursor: pointer;
          width: 407px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          border-left: 1px solid #ffffff47;
        }

        .swiperActive {
          background: fade(@primary, 90%);
        }

        .rightText {
          border: none;
        }
      }
    }

    >.right {
      width: 386px;
      height: 544px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      background-color: #ffffff;
      box-sizing: border-box;
      padding: 20px 18px 0;

      >.topText {

        // border-bottom: 1px solid #f4f4f4;
        // padding-bottom: 10px;
        >.title {
          font-size: 19px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }

        >.tip {
          padding: 17px 0;
          box-sizing: border-box;
          display: flex;

          >.left {
            width: 110px;
            flex-shrink: 1;
            height: 30px;
            border-radius: 5px;
            background-color: #fe9400;
            text-align: center;
            line-height: 30px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
          }

          >.right {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            >.one {
              width: 65px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #00b079;
              padding: 0 8px;
              border-right: 1px solid #d3ede5;

              &:last-child {
                border: none;
              }
            }
          }
        }

        .views {
          height: 20px;
          padding: 17px 0;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          >.viewsPic {
            width: 20px;
            height: 13px;
            background-image: url("../assets/img/detail/liulanliang@2x.png");
            background-size: 100%;

            // position: relative;
            >img.pic {
              width: 100%;
              height: 100%;
            }
          }

          >.text {
            padding: 0 12px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
      }

      >.middleText {
        width: 100%;
        // border-bottom: 1px solid #f4f4f4;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        // padding: 30px 0;
        >.one {
          >.oneTop {
            font-size: 26px;
            font-family: DIN;
            font-weight: bold;
            color: #00b079;

            &::after {
              content: "万 m²";
              padding-left: 9px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #00b079;
            }
          }

          >.oneText {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
      }

      >.bottomTextTip {
        padding: 0 0 30px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        >.tipCard {
          margin-right: 22px;
          width: 67px;
          height: 24px;
          background-image: url("../assets/img/detail/矩形 28 拷贝@2x.png");
          background-size: 100%;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #00b079;
          text-align: center;
          line-height: 24px;
        }
      }

      >.bottomText {
        >.bottomTextTop {
          padding: 11px 0 0;

          >.bottomOne {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            padding: 11px 0;
            box-sizing: border-box;

            >.oneLeft {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
              width: 99px;
            }

            >.oneRight {
              width: 247px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
            }
          }
        }
      }
    }
  }
}

.center-container {
  width: 100%;
  height: 389px;
  background: #fff;
  margin: 0 auto 0;
  justify-content: space-around;
  padding-top: 41px;

  .left {
    padding-top: 7px;
    box-sizing: border-box;
    margin-left: 7px;

    .change-tab {
      width: 815px;
      height: 49px;
      background: url("../assets/img/detail/changeTab-bg.png") no-repeat;
      background-size: 100% 100%;
      align-items: center;

      .tab-item {
        font-size: 16px;
        color: #fff;
        margin: 0 26px 0;
      }
    }

    .content-detail {
      .title-img {
        width: 120px;
        height: 28px;
        display: block;
        margin: 34px 0 26px;
      }

      .substance {
        font-size: 20px;
        line-height: 50px;

        ul {
          width: 308px;

          &:first-child {
            border-right: 1px solid #d2d2d2;
          }

          &:last-child {
            margin-left: 117px;
          }

          .sub-title {
            color: #999999;
            margin-right: 36px;
            font-size: 20px;
          }

          .details {
            color: #333333;
            font-size: 20px;
          }
        }
      }
    }
  }

  .right {
    width: 399px;
    height: 316px;
    background: url("../assets/img/detail/right-deficiencyPic.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 8px;

    .QR-upperRightCorne {
      display: block;
      position: absolute;
      right: 19px;
      top: 8px;
      height: 46px;
      width: 45px;
      z-index: 100;
    }

    .is-max {
      width: 370px;
      height: 300px;
      background-color: #fff;
      z-index: 1;
      text-align: center;
      display: flex;
      justify-content: center;
    }

    .is-max-two {
      width: 380px;
      height: 300px;
      background-color: #fff;
      z-index: 1;
      text-align: center;

      .QR-upperRightCorne {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        height: 46px;
        width: 45px;
        z-index: 100;
      }

      .wx-box {
        display: flex;
        align-items: center;

        .wx-er {
          width: 200px;
          height: 200px;
          padding: 14px;
        }

        .wx-sao {
          margin-left: 15px;

          .wx-sao-img {
            width: 78px;
          }

          .wx-sao-text {
            font-size: 18px;
            margin-top: 21px;
          }
        }
      }

      .wx-foot {
        width: 100%;
        height: 57px;
        font-size: 20px;
        line-height: 57px;
        color: #fff;
        background: #00b079;
        text-align: center;
      }
    }

    .title {
      font-size: 20px;
      font-weight: 600;
      color: #fff;
      line-height: 60px;
      border-bottom: 1px solid #a4a7ab;
      width: 305px;
    }

    .introduce {
      width: 305px;

      .position-name {
        display: block;
        font-size: 18px;
        color: #fff;
        margin-top: 22px;
        margin-bottom: 10px;
      }

      ul {
        li {
          span {
            font-size: 16px;
            color: #fff;
            line-height: 35px;
          }
        }
      }
    }

    .bottom-title {
      box-sizing: border-box;
      padding: 15px 24px 16px 26px;
      position: absolute;
      bottom: 33px;
      width: 359px;
      // height: 72px;
      font-size: 16px;
      color: #fff;
      margin-left: -27px;
      background: rgba(0, 0, 0, 0.3);
    }
  }
}

.center-container2 {
  width: 100%;
  // height: 487px;
  background: #f7faf9;
  margin: 0 auto 0;
  justify-content: space-around;
  padding: 44px 0 24px;

  .container2-content {
    width: 1200px;
    margin: auto;

    img.continer-img {
      width: 120px;
      height: 28px;
      display: block;
      margin-bottom: 39px;
    }

    .content-details {
      width: 1024px;

      // &-box{
      //   &:nth-child(odd){
      //     font-weight: bold;
      //     font-size: 20px;
      //     color: #00b079 !important;
      //   }
      // }
      .details-item {
        .item-title {
          font-size: 20px;
          color: #333333;
          font-weight: 600;
          text-align: left;
          margin-bottom: 19px;
        }

        .item-content {
          font-size: 18px;
          color: #333333;
          text-align: left;
          line-height: 28px;
          margin-bottom: 27px;
        }
      }
    }
  }
}

.center-container3 {
  width: 100%;
  height: 596px;
  background: url("../assets/img/detail/containter3-bg.png") no-repeat;
  background-size: 100% 100%;
  margin: 0 auto 0;
  justify-content: space-around;

  padding-top: 41px;

  .tabs {
    background: #ffffff;
    padding: 12px 12px 0 12px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tabs__item {
      color: @ghost;
      font-size: 16px;
    }

    .flex {
      align-items: flex-end;
      height: 44px;
      margin-top: -10px;
    }

    .address-img {
      height: 25px;
    }

    .address-name {
      color: #ff9500;
      font-size: 16px;
      margin-left: 10px;
    }

    .is-active {
      color: @primary;

      >.tabs__item {
        color: @primary;
      }
    }
  }

  .bdmap {
    padding: 0 12px 12px;
    background: #ffffff;
    box-sizing: border-box;

    >.maps {
      position: relative;
      width: 100%;
      height: 480px;
      margin: 0 auto;

      .el-collapse {
        position: absolute;
        top: 40px;
        left: 40px;
        width: 350px;
        height: 50px;
        padding: 0 10px;

        .is-active {
          border-bottom: 1px solid fade(@ghost, 50%);
        }
      }
      
    }
  }
}

.center-container4 {
  width: 100%;
  height: 394px;
  background: #f7faf9;
  margin: 0 auto 0;
  justify-content: space-around;
  padding-top: 48px;

  .container4-content {
    width: 1200px;
    margin: auto;

    .container4-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-content: center;
      margin-bottom: 47px;

      .related-title {
        width: 120px;
        height: 28px;
      }

      .seeMore {
        cursor: pointer;
        width: 92px;
        height: 40px;
        font-size: 16px;
        // font-weight: bold; 
        color: #fff;
        background: url("../assets/img/detail/seeMore.png") no-repeat;
        background-size: 100% 100%;
        text-align: center;
        line-height: 40px;
      }
    }

    .card-container {
      width: 100%;

      .card {
        display: flex;
        position: relative;
        z-index: 10;

        .card-item {
          width: 270px;
          height: 194px;
          margin-right: 40px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: end;
          padding-bottom: 35px;
          box-sizing: border-box;

          .bg {
            width: 270px;
            height: 194px;
            position: absolute;
            bottom: 0px;
          }

          &:last-child {
            margin-right: 0px;
          }

          .subscript {
            position: absolute;
            background: url("../assets/img/detail/subscript.png") no-repeat;
            background-size: 100% 100%;
            color: #fff;
            font-size: 18px;
            font-weight: 600;
            width: 49px;
            height: 56px;
            top: 0px;
            left: 0px;
            text-align: center;
            line-height: 35px;
            z-index: 2;
          }

          .card-content {
            width: 270px;
            font-size: 12px;

            display: flex;
            justify-content: space-between;
            z-index: 100;
            padding: 7px 0;
            margin-bottom: -5px;
            background: rgba(0, 0, 0, 0.5);

            .title {
              width: 190px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-left: 5px;
              color: #fff;
              font-weight: bold;
            }

            .locate {
              margin-right: 5px;
              display: flex;
              align-items: center;
              color: #fff;

              .locate-address {
                display: inline-block;
                // width: 60px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: #fff;
              }

              .local-icon {
                width: 10px;
                height: 12px;
                display: flex;
                margin-right: 7px;
              }
            }
          }

          .card-bottom {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 30px;
            background: fade(@primary, 50%);
            background-size: 100% 100%;
            display: flex;
            align-content: center;
            justify-content: space-around;

            span {
              font-size: 14px;
              line-height: 30px;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>