import request from "@/utils/request";
let lngxi;
export default lngxi = {
  warehouseBannerList: function (data) {
    return request({
      url: `/system/lxPage/warehouseBannerList?warehouseId=${data}`,
      method: "get",
    });
  },
  warehouseDetailById: function (warehouseId) {
    return request({
      url: `/system/lxPage/warehouseDetailById/${warehouseId}`,
      method: "get",
    });
  },
};
